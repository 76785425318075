import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RichText from "../components/rich-text"

const ProjectPage = ({ pageContext }) => (
  <Layout>
    <SEO title={pageContext.title} />
    <section className="project">
      <div className="project__teaser">
        <img
          className="project__image"
          alt={`Cover for ${pageContext.title}`}
          src={pageContext.coverImage.file.url}
          {...pageContext.coverImage.file.details.image}
        />
        {pageContext.showTitle && (
          <h1 className="project__title">{pageContext.title}</h1>
        )}
      </div>
      {pageContext.text && (
        <RichText className="project__text" source={pageContext.text.text} />
      )}
    </section>
  </Layout>
)

export default ProjectPage

import React from "react"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

const fixHTML = html => html.replace(/\n/g, "<br />")

const RichText = ({ source, className, ...otherProps }) => {
  const sourceObject = JSON.parse(source)
  const html = fixHTML(documentToHtmlString(sourceObject))
  return (
    <div
      {...otherProps}
      className={`rich-text ${className}`.trimRight()}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default RichText
